import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import JaegerLeCoultureCategoryBtns from '../../components/preowned/JaegerLeCoultureCategoryBtns'

// markup
const UsedJaegerlecoultrerendezvouswatches = () => {
  const data = useStaticQuery(
    graphql`
      query queryJaegerlecoulturerendezvous {
        products: allStrapiProduct(
          filter: { brand: { eq: "Jaeger LeCoulture" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Jaeger LeCoultre Rendez Vous Watches for Sale'}
      canonical={'/fine-watches/other-watches-brands/jaeger-lecoultre/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Jaeger LeCoultre Rendez Vous with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
              <StaticImage
                src="../../images/preowned/preowned-certifeid-used-jaeger-lecoulture-reverso-watches-banner-1920x1080.jpg"
                alt="Pre-Owned Certified Used Jaeger LeCoulture Watches Header"
                aria-label="Used Jaeger LeCoulture Watch Site Header"
              />
            </a>
          </div>
          {/* BANNERS FOR THE JLC PAGES NEED TO BE UPDATED OR HAVE PLACE HOLDER */}
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">
              Buy Sell Trade Consign Repair Restore Jaeger LeCoultre Rendez Vous Watches
            </h1>
            <h2>
              {' '}
              BUY SELL TRADE CONSIGN REPAIR RESTORE
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                Jaeger LeCoultre Rendez Vous{' '}
              </a>{' '}
              WATCHES{' '}
            </h2>
            <h3>
              BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED{' '}
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                {' '}
                Jaeger LeCoultre Rendez Vous
              </a>{' '}
              WATCHES WITH GRAY AND SONS JEWELERS
            </h3>
            <h2 className="text-2xl text-red-700">
              History of Jaeger LeCoultre Rendez Vous Watches{' '}
            </h2>
            <p>
              For over the last 150 years, Jaeger LeCoultre has created a legacy respected
              worldwide. As one of the grandfathers of innovations in the watch industry, JLC has
              come up with hundreds of patents and movements that are now used by other luxury watch
              manufacturers like Audemars Piguet and Piaget. Throughout their collections, Jaeger
              LeCoultre has created thousands of timepieces fit to cater to all of their consumers.
              Visibly, Jaeger LeCoultre and their manufacturing team take tremendous pleasure in
              experimenting with their movements and timepieces. Unlike other high-end watch
              retailers, Jaeger LeCoultre has created a vast collection of not only men's timepieces
              but women’s timepieces as well. One of these collections Jaeger LeCoultre has crafted
              exclusively for their female audience is the Rendez Vous collection. Recognized as one
              of Jaeger LeCoultre's youngest collections, the Rendez Vous consists of pure beauty
              and elegance. The Rendez Vous made its first debut at the Abu Dhabi film festival in
              2012 displaying a vibrant appearance. The art-deco-style watch displayed a guilloche
              dial in a classic round case and brilliant-cut diamonds around the bezel, elegance at
              its finest. Within this luxurious Jaeger LeCoultre collection fall an exquisite line
              of Rendez-Vous watches, each holding their unique attribute, yet part taking in the
              sophistication and excellence that is the Rendez-Vous watch collection. Here are some
              examples of a few. Currently one of the most coveted from the collection is the Jaeger
              LeCoultre Rendez-Vous Perpetual Calendar. This watch combines all that is the beauty
              and fineness of the collection with top-of-the-line mechanisms and complications. The
              alluring guilloche dial consists of a perpetual calendar with a 4-digit year window
              and a moon phase display, enclosed by a row of diamonds along the case edge. This
              Jaeger LeCoultre Rendez-Vous watch has an attention to detail like no other, as
              displayed by a ravishing rose-cut diamond placed on the watch’s crown. Another
              stunning watch model from the JLC collection is the Jaeger LeCoultre Rendez-Vous
              Celestial. This unique timepiece gets its name from displaying a passage of the
              Northern Hemisphere constellations, which is shown on its blue dial. The round-shaped
              watch case holds a row of bead-set brilliant-cut diamonds and a uniquely shaped
              display of Arabic numeral markers. The Jaeger LeCoultre Rendez Vous Tourbillon consist
              of three different models, the Jaeger LeCoultre Tourbillon, the Tourbillon Night &amp;
              Day, and Tourbillon Wild. Watch enthusiasts around the world admire the different
              designs for this Rendez-Vous, each elaborating the window displaying the Tourbillon
              function. Anyone looking for watches to display what Haute Horlogerie is should look
              no further than the Jaeger LeCoultre Rendez-Vous Sonatina collection. Combining the
              art that comes with a piece of jewelry and the function of a high-caliber watch
              mechanism, the JLC Rendez-Vous Sonatina has a variety of displays of art pieces on the
              dials. Each one is still a perfect company for a lady’s wrist. With the vibrance and
              complication that is the JLC Rendez-Vous collection, a true watch connoisseur cannot
              deny this line is the epitome of fine watchmaking. Perfect for any occasion, anyone
              looking to level up the sophistication of their appearance should absolutely look into
              acquiring one of these beautiful Jaeger LeCoultre Rendez-Vous watch models.
              <br />
              <br />
              Gray and Sons Jewelers sells certified authentic pre-owned JAEGER LECOULTRE REVERSO
              Watches. Gray and Sons and their buying division &ensp;
              <a href="https://sellusyourjewelry.com/">sellusyourjewelry.com</a> Will also buy your
              used{' '}
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                Jaeger LeCoultre Reverso
              </a>{' '}
              Watch for cash. We are the #1 independent watch dealer. Our expert, Swiss trained,
              watch repairmen will service your watch and replace your{' '}
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                Jaeger LeCoultre Reverso
              </a>{' '}
              Watch battery. We have a huge in-stock inventory at amazing prices. Talk to a real
              representative now.
              <br />
              <br />
              <h3>
                Buying and Selling Pre-Owned{' '}
                <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                  {' '}
                  Jaeger LeCoultre Rendez Vous
                </a>{' '}
                watches with gray &amp; sons jewelers.
              </h3>
              <p>
                Since its release, the JLC Rendez-Vous has been a huge success. These elegant
                timepieces draw the attention of any watch enthusiast that comes near them. The
                Jaeger LeCoultre Rendez-Vous collection is one of Jaeger LeCoustre’s versatile
                pieces appropriate for almost any setting. Under the umbrella of Jaeger LeCoultre
                Rendez Vous watches fall under the following references:
              </p>
              <li>
                {' '}
                &ensp;{' '}
                <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                  Popular Pre-Owned Used Jaeger LeCoultre Rendez Vous Watch References and Models:
                </a>{' '}
              </li>
              <li>
                {' '}
                &ensp;
                <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                  Pre-Owned Jaeger LeCoultre Rendez Vous Night Day References: Q3442430, Q3448430,
                  Q3442150{' '}
                </a>{' '}
              </li>
              <li>
                {' '}
                &ensp;
                <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                  Pre-Owned Jaeger LeCoultre Rendez Vous Tourbillon References: Q3413406, Q3412490,
                  Q3413480
                </a>{' '}
              </li>
              <li>
                {' '}
                &ensp;
                <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                  Pre-Owned Jaeger LeCoultre Rendez Vous Perpetual Calendar References: Q3493420,
                  Q3492420{' '}
                </a>{' '}
              </li>
              <li>
                {' '}
                &ensp;
                <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                  Pre-Owned Jaeger LeCoultre Rendez Vous Sonatina References: Q35924A1, Q35924P1,
                  Q35924T1, Q35924C1{' '}
                </a>{' '}
              </li>
              <br />
              <br />
              Since 1980, <a href="/">Gray and Sons</a> Jewelers has been catering to clients all
              around the country with all their luxury needs. All of the services we provide are
              done in our very own store, here, in the heart of Miami, Florida. We are the top watch
              store in all of South Florida specializing in buying pre-owned Jaeger LeCoultre
              watches,Selling used Jaeger LeCoultre watches, repairing second-hand Jaeger LeCoultre
              watches, and trading genuine Jaeger LeCoultre timepieces. Gray and Sons Jewelers is
              the #1 all-stop shop for all Jaeger LeCoultre watch needs. Our store is located in
              South Florida in the city of Surfside across the street from the famous Bal Harbour
              Shops. Aside from buying Jaeger LeCoultre Watches and selling genuine pre-owned Jaeger
              LeCoultre watches, <a href="/">Gray and Sons</a>
              is also a certified repair center for all Swiss-made wristwatches. We have six
              master-trained watchmakers in-house with over 150 years of combined experience to
              repair Jaeger LeCoultre watches, including the reparation of a Jaeger LeCoultre
              Reverso. We take in repairs for swiss-made watches daily!
              <br />
              <br />
              For more details, visit our showroom in Surfside, Miami, or check our website{' '}
              <a href="/fine-watches/JaegerLeCoulture/">www.grayandsons.com</a>. Our company is
              proud to have qualified and trusted in-house watchmakers and jewelers who stand ready
              to assist our customers. Make the best choice and buy your dream certified pre-owned
              Jaeger LeCoulture watch for the best price. The finest selection of used Jaeger
              LeCoulture watches - only at{' '}
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                Gray &amp; Sons.
              </a>
              . Those looking to “get cash for my used Jaeger LeCoultre watch” or “sell my pre-owned
              Jaeger LeCoultre watch today” should visit <a href="/">www.grayandsons.com</a>
              or{' '}
              <a href="https://sellusyourjewelry.com/what-are-you-looking-to-sell-2/">
                {' '}
                www.sellusyourjewelry.com
              </a>{' '}
              to request a free watch quote to sell or trade-in for one of ours! We have a
              brick-and-mortar store located in the heart of Miami, Fl near cities like Sunny Isles,
              Haulover, Aventura, Hollywood, Fort Lauderdale, Miami Beach, Coconut Grove, and Coral
              Gables, Key Biscayne, and many more. On our website, we have LIVE representatives
              Viktoria and Rich who are specialists in buying and selling Jaeger LeCoultre Reverso
              watches. Our LIVE CHAT feature allows clients all around the nation to make all
              inquiries about Jaeger LeCoultre watches and possibly work out a deal in real-time.
              Gray and Sons Jewelers is the best and most reliable place to buy used pre-owned
              Jaeger LeCoultre watches. Our master-trained watchmakers assure every Jaeger LeCoultre
              watches in our inventory is certified authentic and serviced ready for wear with a
              1-year warranty covering the movement.
              <br />
              <br />
              We are so excited to hear from you and have you sign up for our free monthly catalog.
              Visit the store, call or visit our website at <a href="/">www.grayandsons.com</a>.
              Lot’s of people are buying used and pre-owned Jaeger LeCoulture Watches, and many
              people are looking for a place to sell their used Jaeger LeCoulture Watches. Gray and
              Sons will do both. If you’re one of the people saying, “I want to sell my Jaeger
              LeCoulture wrist watch for cash” visit <a href="/">www.grayandsons.com</a> and&ensp;
              <a href="https://sellusyourjewelry.com/">sellusyourjewelry.com</a>
              and we will buy your pre-owned Jaeger LeCoultre Reverso Watch for cash. Would you like
              to <a href="/chat-with-rich">MAKE AN OFFER</a> on watches and jewelry? Vika and Rich
              are live expert decision makers and ready to work with you now.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                <button>Shop Jaeger LeCoultre Reverso Watches</button>{' '}
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK Jaeger LeCoulture WATCHES</h2>
        <iframe
          className="content-center"
          src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d7178.937737903439!2d-80.1235056!3d25.8869528!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x88d9b2992b408533%3A0x8d702d6011cda310!2sGray%20and%20Sons%20Jewelers%7C%20Luxury%20Estate%20Watches%20%26%20Jewelry%209595%20Harding%20Ave%20Surfside%2C%20FL%2033154!3m2!1d25.8869528!2d-80.1235056!5e0!3m2!1sen!2sus!4v1655907861510!5m2!1sen!2sus"
          width="1250"
          height="300"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        <ProductGrid products={data.products.nodes} />
      </section>

      <section>
        <h2 className="text-center py-10">MORE Jaeger LeCoulture FROM GRAY &amp; SONS</h2>

        <section>
          <JaegerLeCoultureCategoryBtns />
        </section>
      </section>

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/Ih7LroA6z4A'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default UsedJaegerlecoultrerendezvouswatches
